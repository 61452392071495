import {
  Injectable,
  OnDestroy,
} from '@angular/core';
import {AngularFireAuth} from '@angular/fire/compat/auth';
import {AngularFirestore} from '@angular/fire/compat/firestore';
import {AngularFireFunctions} from '@angular/fire/compat/functions';

import {
  Observable,
  Subject,
} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

import {AuthService} from '../../auth/services/Auth/auth.service';

export interface Scheduled {
  received: string;
  type: string;
  date: any;
  classId: any;
  complete: boolean;
}

@Injectable({providedIn: 'root'})
export class ScheduleService implements OnDestroy {

  private status = new Subject();
  private onDestroy$: Subject<void> = new Subject<void>();

  scheduled: any;
  scheduled$: any[] = [];
  // scheduled$: Array<{
  //   data: Scheduled,
  //   id: string
  // }>;

  private user = new Subject();

  private _bound = false;

  callableCF: {
    removeScheduled: any;
    actionNow: any;
    updateSchedule: any;
  };

  constructor(
    private afAuth: AngularFireAuth,
    private firestore: AngularFirestore,
    private fns: AngularFireFunctions,
    private authService: AuthService
  ) {
    this.callableCF = {
      removeScheduled: fns.httpsCallable('schedule-removeScheduledTask'),
      actionNow: fns.httpsCallable('schedule-actionNow'),
      updateSchedule: fns.httpsCallable('schedule-updateSchedule'),
    };

    this.authService.loggedInTrigger.subscribe((loggedInValue: boolean) => {
      if (loggedInValue) {
        this._userWatch();

        this.scheduled$ = [];

        this.user.next(this.scheduled$);
      }
    });

    this.authService.loggedOutTrigger.subscribe(() => {
      this.ngOnDestroy();
    });
  }

  getStatus(): Observable<any> {
    return this.status.asObservable();
  }

  getUser(): Observable<any> {
    return this.user.asObservable();
  }

  bindToUser(user) {

    if (this._bound || !user || !user.auth.fbToken || !this.afAuth.currentUser) {
      return;
    }

    const customerId = user.gSuiteId;
    const userId = user.profile.id;

    this.scheduled = this.firestore.collection(`customers/${customerId}/users/${userId}/schedule`, ref => ref.orderBy('request.date', 'asc'));

    this.scheduled.snapshotChanges()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(data => {
        this.scheduled$.length = 0;

        if (data) {
          this.scheduled$ = data.map(e => ({
              data: e.payload.doc.data(),
              id: e.payload.doc.id
            }));

          this.user.next(this.scheduled$);
        }

        return data;

      });

    this._bound = true;

  }

  ngOnDestroy() {
    this.onDestroy$.next();
  }

  removeScheduled(id: string) {
    return this.callableCF.removeScheduled({
      id
    }).toPromise();
  }

  actionNow(id: string) {
    return this.callableCF.actionNow({
      id
    }).toPromise();
  }

  updateSchedule(id: string, expiry: number): Observable<any> {
    return this.callableCF.updateSchedule({
      id, expiry
    });
  }

  private _userWatch() {
    this.authService.getActiveUser().pipe(takeUntil(this.onDestroy$)).subscribe((user) => {
      if (user && user.auth && user.auth.fbToken) {
        this.bindToUser(user);
      }
    });
  }
}
